import { client } from "@/sanity/lib/client";
import { groq } from "next-sanity";

export const pageBuilderQuery = `
pageBuilder[]{
  ...,
  _type == 'header' => {
    ...,
    excludeSeo,
  },
  _type == 'videoText'=> {
    caption,
    description,
    heading,
    url,
    heading,
    position,
    spacing,
    background,
  },
  _type == 'gridPost'=> {
    button,
    postList[]->{
      publishedAt,
      image,
      estimatedReadTime,
      postCategory,
      title,
      subtitle,
      postTags,
      slug {
        current
      },
    },
  },
  _type == 'productFeature'=> {
    spacing,
    background,
    imagePosition,
    description,
    featuredProduct->{
      title,
      subtitle,
      price,
      link,
      productTheme,
      category ->{
        slug {
          current,
        }
      },
      slug {
        current,
      },
      image,
    },
  },
  _type == 'cardList'=> {
    listType,
    title,
    spacing,
    edenCardList,
    background,
    articleReferenceList[]->{
      publishedAt,
      image,
      estimatedReadTime,
      postCategory,
      title,
      subtitle,
      postTags,
      postContent,
      slug {
        current
      },
    },
    productReferenceList[]->{
      image,
      price,
      productTheme,
      category ->{
        slug {
          current,
        }
      },
      slug {
        current,
      },
      title,
      subtitle,
    },
    heading,
  },
  _type == 'textImage'=> {
    ...,
    content[]{
      ...,
      "file": file.asset->url,
      },
  },
  _type == 'textVideo'=> {
    ...,
    video {
      asset->{
        url,
      },
    },
    content[]{
      ...,
      "file": file.asset->url,
      },
  },
  _type == 'fileDownload'=> {
    background,
    spacing,
    image,
    buttonList[]{
      external,
      type,
      href,
      label,
      target,
      variant,
      "file": file.asset->url,
    },
  },
  _type == 'multiColumn'=> {
    background,
    spacing,
    heading,
    addButton,
    button,
    columnList[]{
      icon,
      addButton,
      button,
      text,
      title,
    },
  },
  _type == 'shopByCategory'=> {
    "categoryList": *[_type == "productCategory"]{
      description,
      slug,
      icon,
      title
    }
  },
}
`;
export const GET_SITE_SETTINGS = async () => {
  try {
    let res = await client.fetch(
      groq`
         {
            "siteSettings": *[_type == "siteSettings"][0]{
                seo {
                    seoDescription,
                    seoTitle,
                    seoKeywords
                },
                contact,
                email
            } ,
            "categoryList": *[_type == "productCategory"]{
              title,
              slug {
                current
              },
            },
            "navigation": *[_type == "navigation"][0]{
                navItemList[]{
                    items[]{
                      imageOverlay{
                        asset->{
                          url,
                        },
                        crop,
                        hotspot,
                      },
                        linkLabel,
                        label,
                        link,
                       
                    },
                    label,
                    link,
                }
            },
            "footerSettings": *[_type == "footerSettings"]{...,},
         }
        `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const GET_CAMPAIGN = async (slug: string) => {
  try {
    let res = await client.fetch(
      groq`
        *[_type == "campaign" && slug.current == "${slug}" ]{
            title,
            publishedAt,
            isDark,
            "slug":slug.current,
            ${pageBuilderQuery},
            content,
            seo
        }
      `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const GET_PAGE = async (slug: string) => {
  try {
    let res = await client.fetch(
      groq`
        *[_type == "page" && slug.current == "${slug}" ]{
            title,
            publishedAt,
            "slug":slug.current,
            ${pageBuilderQuery},
            content,
            seo {
              ...,
              "seoImage": seoImage.asset->url,
            },
            "categoryList": *[_type == "productCategory"]{
              title,
              icon,
              slug {
                current
              },
            },
        }
      `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const GET_PRODUCT_CATEGORY = async (slug: string) => {
  try {
    let res = await client.fetch(
      groq`
        *[_type == "productCategory" && slug.current == "${slug}" ]{
            title,
            publishedAt,
            "slug":slug.current,
            ${pageBuilderQuery},
            content,
            seo
        }
      `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const GET_PRODUCT_CATEGORY_DATA = async (slug: string) => {
  try {
    let res = await client.fetch(
      groq`
        *[_type == "productCategory" && slug.current == "${slug}" ]{
            title,
            publishedAt,
           description,
            "categoryList": *[_type == "productCategory"]{
              title,
              icon,
              slug {
                current
              },
            },
            "slug":slug.current,
            ${pageBuilderQuery},
            content,
            seo,
            "productList": *[_type == "product" && category->slug.current == "${slug}" ]
        }
      `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const GET_PRODUCT = async ({
  category,
  slug,
}: {
  category: string;
  slug: string;
}) => {
  try {
    let res = await client.fetch(
      groq`
        *[_type == "product" && slug.current == "${slug}" && category->slug.current == "${category}" ]{
            title,
            subtitle,
            price,
            image,
            productTheme,
            certificationTagList[]->{title, image},
            productSizeList,
            productDetails,
            link,
            tags,
            galleryImages,
            category-> {
              title,
              slug {
                current
              },
            },
            slug {
              current
            },
            publishedAt,
            "slug":slug.current,
            ${pageBuilderQuery},
            content,
            seo
        }
      `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const GET_ALL_PRODUCT = async () => {
  try {
    let res = await client.fetch(
      groq`
        *[_type == "product" ]{
          title,
          subtitle,
          price,
          productTheme,
          image,
          slug {
            current
          },
          category->{
            title,
            slug {
              current
            },
          }
        }
      `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const GET_ALL_POST = async () => {
  try {
    let res = await client.fetch(
      groq`
        *[_type == "post" ]{
          estimatedReadTime,
            postCategory,
            image,
            title,
            subtitle,
            slug {
              current
            },
            publishedAt,
            postTags,
        }
      `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const GET_POST = async (slug: string) => {
  try {
    let res = await client.fetch(
      groq`
        *[_type == "post" && slug.current == "${slug}" ]{
            estimatedReadTime,
            postCategory,
            publishedAt,
            title,
            subtitle,
            ${pageBuilderQuery},
            seo,
            slug {
              current,
            },
            postContent[]{
              ...,
              _type == "block" => {
                ...,
                markDefs[]{
                  ...,
                  _type == "internalLink" => {
                     reference->{
                      _type,
                      slug{
                        current
                      },
                      category->{
                        slug{
                          current,
                        },
                      },
                     },
                  },
                },
              },
            },
        }
      `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const GET_ALL_SITE_PAGE = async () => {
  try {
    let res = await client.fetch(
      groq`
       { 
        "pageList": *[_type == "page" ]{
          publishedAt,
          slug {
            current
          },
        },
        "productCategoryList": *[_type == "productCategory" ]{
          publishedAt,
          slug {
            current
          },
        },
        "productList": *[_type == "product" ]{
          publishedAt,
          slug {
            current
          },
          category->{
            slug {
              current
            },
          },
        },
        "postList": *[_type == "post" ]{
          publishedAt,
          slug {
            current
          },
        },
      }
      `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
