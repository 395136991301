"use client";
import Eden from "@/components/molecules/Eden/Eden";
import { HeaderMainProp } from "@/types/TypeList";
import { urlForImage } from "@/sanity/lib/image";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
// import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import gsap from "gsap";

import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useGlobalStore } from "@/lib/globalStore";
import Link from "next/link";
gsap.registerPlugin(ScrollTrigger);
type Props = {
  data: HeaderMainProp;
  index?: number;
  lastIndex?: number;
};

const HeaderMain = (props: Props) => {
  const { data, index, lastIndex } = props;

  const { getStartedOpen, setGetStartedOpen } = useGlobalStore();
  const ref = useRef(null);
  useGSAP(
    () => {
      const tl = gsap.timeline({
        delay: 0.8,
        scrollTrigger: {
          trigger: ".eden-text",
        },
      });

      tl.fromTo(
        ".eden-text",
        { scale: 0, opacity: 0, transformOrigin: "bottom left" },
        { scale: 1, opacity: 1, ease: "power4.out" }
      ).fromTo(
        ".eden-subtext",
        { scale: 0, opacity: 0, transformOrigin: "top left" },
        { scale: 1, opacity: 1, ease: "power4.out" },
        ">0.2" // This adds a slight delay after the first animation completes. Adjust the delay as needed.
      );
    },
    { scope: ref }
  );

  let imageExists = data.image ? `` : `bg-[url('/header-main-bg.jpg')] `;
  const divProps = {
    style: data.image && {
      backgroundImage: `url(${urlForImage(data.image).format("webp").url()})`,
    },
    className: `px-4 py-6 sm:p-8 md:p-16 bg-cover ${imageExists} ${
      index === lastIndex && "rounded-b-none sm:rounded-2xl"
    } ${
      index === 0 && "rounded-t-none sm:rounded-2xl"
    } rounded-2xl relative overflow-hidden`,
  };
  console.log(data);
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  return (
    <section
      ref={ref}
      className={cn(sectionBackground, sectionSpacing, "!py-0 relative")}
    >
      <div className="sm:container sm:py-6">
        <div {...divProps}>
          <div className="w-full z-[1] h-full absolute bg-black/30 left-0 top-0"></div>
          <div className="flex relative z-[2] origin-bottom-left flex-col sm:flex-row gap-2 sm:gap-4 mb-2 sm:mb-4 heading-header-main">
            <div
              className={`w-full max-w-[66px] sm:max-w-[100px] md:max-w-[125px] h-fit aspect-square`}
            >
              <Eden />
            </div>
            <div className="rounded-tl-[14.5px] eden-text sm:bg-white flex items-center sm:justify-center rounded-tr-[14.5px] rounded-br-[14.5px]">
              {data.excludeSeo ? (
                <p
                  className={`text-[#0C1E20] text-left md:leading-[84px]  py-4 sm:py-0 text-[38px] sm:text-[48px] md:text-[77px]  rounded-b-[14.5px] rounded-tr-[14.5px] bg-white sm:bg-transparent font-bold transition-all px-6`}
                >
                  {data.edenText}
                </p>
              ) : (
                <h1
                  className={`text-[#0C1E20] text-left md:leading-[84px]  py-4 sm:py-0 text-[38px] sm:text-[48px] md:text-[77px]  rounded-b-[14.5px] rounded-tr-[14.5px] bg-white sm:bg-transparent font-bold transition-all px-6`}
                >
                  {data.edenText}
                </h1>
              )}
            </div>
          </div>
          <div className=" flex relative z-[2] subheading-header-main">
            <div className="bg-white eden-subtext text-[38px] sm:text-[48px] md:text-[77px] pb-4 px-4 mb-8 rounded-bl-[14.5px] max-w-[800px] rounded-tr-[14.5px] rounded-br-[14.5px]">
              <p className="text-[#0C1E20] w-full text-left font-bold md:leading-[84px]">
                {data.subHeading}
              </p>
            </div>
          </div>
          {data.addButton && (
            <div className="py-4 sm:py-8 relative z-[2] flex justify-start">
              <Link href={data?.button?.href || "/"}>
                <Button size={"lg"} variant={data?.button?.variant}>
                  {data?.button?.label}
                </Button>
              </Link>
            </div>
          )}
          {data.subtext && (
            <div className="max-w-[675px] text-left relative z-[2] mt-24 font-semibold subtext-header-main text-subtitle sm:text-[24px] md:text-[27px] ">
              <h5>{data.subtext}</h5>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HeaderMain;
